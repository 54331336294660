<template>
	<div class="container">
		<el-form :model="formData" status-icon label-width="100px">
			<el-form-item label="客户名称" prop="username">
				<el-input v-model="formData.username"></el-input>
			</el-form-item>
			<el-form-item label="终端用户名称" prop="password">
				<el-input type="password" v-model="formData.password" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="公司名称" prop="checkPass">
				<el-input v-model="formData.email" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="公司地址" prop="type">
				<el-input v-model.number="formData.type"></el-input>
			</el-form-item>
			<el-form-item label="公司邮箱" prop="corporateName">
				<el-input v-model="formData.corporateName"></el-input>
			</el-form-item>
			<el-form-item label="国家" prop="corporateName">
				<el-input v-model="formData.corporateName"></el-input>
			</el-form-item>
			<el-form-item label="城市" prop="corporateName">
				<el-input v-model="formData.corporateName"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="info">取消</el-button>
				<el-button type="primary">确认</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				formData: {
					username: '',
					password: '',
					email: '',
					type: '',
					corporateName: ''
				}
			}
		},
		methods: {}
	}
</script>

<style lang="less" scoped>
	.el-form-item {
		.el-input {
			width: 500px;
		}

		.el-button {
			width: 200px;
			margin-right: 90px;
		}
	}
</style>
